<template>
  <SliderSectionSwiperWrapper
    v-model="swiper"
    :title="title"
    :link="{
      name: 'Voir tous nos événements culturels',
      slug: '/autour-du-voyage/evenement-culturel'
    }"
    :slider-props="{
      slidesPerView: 1.15,
      spaceBetween: 16,
      slidesOffsetBefore: 24,
      slidesOffsetAfter: 16,
      breakpoints: {
        [BREAKPOINTS.mobile]: {
          slidesPerView: 'auto',
          spaceBetween: 24,
          slidesOffsetBefore: 64,
          slidesOffsetAfter: 64
        }
      }
    }"
    class="event-slider"
    :icon="nameIcon ? (nameIcon as string) : geographicZoneIconOnTitle"
  >
    <template #swiper-elements>
      <SwiperSlide
        v-for="event of eventsWithGeographicZone"
        :key="event.attributes.title"
        class="swiper-element"
      >
        <div class="event">
          <TwicImg
            :src="event.attributes.image?.data?.attributes.url"
            class="image"
            :alt="event.attributes.title"
            ratio="2:1"
          />
          <div class="icon-container">
            <Icon
              v-if="event.geographicZoneIcon"
              :name="event.geographicZoneIcon"
              width="200px"
              height="200px"
              fill="rgba(186, 188, 189, 65%)"
            />
          </div>
          <!-- TODO: Change to only one shadowed text when css modules integrated -->
          <ShadowedText
            class="name-mobile"
            :text="`<span class='title'>${event.attributes.title}</span>`"
          />

          <div class="right">
            <!-- TODO: Change to only one shadowed text -->
            <ShadowedText
              class="name-desktop"
              :text="`<span class='title'>${event.attributes.title}</span>`"
            />
            <div class="content">
              <div class="top-line">
                <div class="date">
                  {{
                    formatEventDateISO({
                      startDate: event.attributes.startDate,
                      endDate: event.attributes.endDate
                    })
                  }}{{ event.attributes.startTime ? ' à ' + event.attributes.startTime : '' }}
                </div>
                <div class="type">{{ event.attributes.tags?.data[0]?.attributes.name }}</div>
              </div>

              <!-- eslint-disable vue/no-v-html -->
              <div
                v-if="event.attributes.text"
                class="text"
                v-html="DOMPurify.sanitize(event.attributes.text)"
              />
              <!-- eslint-enable vue/no-v-html -->

              <div class="bottom-line">
                <NuxtLink
                  class="more"
                  :to="`/autour-du-voyage/evenement-culturel/${event.attributes.tags?.data[0]?.attributes.slug}/${event.attributes.slug}`"
                >
                  En savoir +
                </NuxtLink>
              </div>
              <!-- TODO : rules for events access must be reviewed and setup -->
              <div class="access-mobile"></div>
              <NuxtLink
                class="cta-mobile"
                :to="`/autour-du-voyage/evenement-culturel/${event.attributes.tags?.data[0]?.attributes.slug}/${event.attributes.slug}`"
                >En savoir +</NuxtLink
              >
            </div>
          </div>
        </div>
      </SwiperSlide>
    </template>
  </SliderSectionSwiperWrapper>
  <NuxtLink class="mobile-event-link link" href="/autour-du-voyage/evenement-culturel">
    Voir tous nos événements culturels
  </NuxtLink>
</template>

<script lang="ts" setup>
import DOMPurify from '@/lib/DOMPurify';
import { getGeographicZonePicto } from '@/lib/getGeographicZonePicto';
import { BREAKPOINTS } from '@/lib/variables';

import { Event } from '@/lib/strapi-types/Event';

import { SwiperBinding } from '@/lib/types/models/swiper';

const props = defineProps<{
  events: Event[];
  title: string;
  geographicZoneSlug?: string;
  countrySlugs?: string[];
  nameIcon?: string;
}>();

const eventsWithGeographicZone = computed(() =>
  props.events.map(event => {
    const geographicZoneSlug =
      event.attributes.geographicZones?.data.length === 1
        ? event.attributes.geographicZones?.data[0]?.attributes.slug
        : '';

    const countrySlugs =
      event.attributes.countries?.data.length === 1
        ? [event.attributes.countries?.data[0]?.attributes.slug ?? '']
        : [];
    return {
      ...event,
      geographicZoneIcon: geographicZoneSlug
        ? getGeographicZonePicto({ geographicZoneSlug, countrySlugs })
        : undefined
    };
  })
);

const geographicZoneIconOnTitle = props.geographicZoneSlug
  ? getGeographicZonePicto({
      geographicZoneSlug: props.geographicZoneSlug,
      countrySlugs: props.countrySlugs
    })
  : undefined;

const swiper = ref<SwiperBinding>();
</script>

<style lang="scss" scoped>
@use '$/components/shadowed-text.scss';
@use '$/border-radius.scss';
@use '$/button.scss';
@use '$/colors.scss';
@use '$/misc.scss';
@use '$/shadows.scss';
@use '$/spacings.scss';
@use '$/breakpoints.scss';
@use '$/text-ellipsis.scss';

.link {
  display: none;
}

.event-slider {
  flex-direction: column;
  height: fit-content;
  margin-bottom: 16px;

  .event {
    $border-radius: border-radius.$default;

    position: relative;

    flex-shrink: 0;
    align-items: flex-end;

    width: 880px;
    height: 550px;

    background-color: white;
    border: solid 1px colors.$grey-300;
    border-radius: $border-radius;

    // transform: translateZ(0) !important; // remove safari flickering

    .image {
      user-select: none;

      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 360px;

      object-fit: cover;
      filter: brightness(0.5);
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }

    .icon-container {
      z-index: 2;
      transform: translateZ(0);

      flex: none;

      width: 220px !important;
      height: 220px !important;
      margin: 80px 42px;
    }

    .name-mobile {
      @include shadowed-text.small();

      position: absolute;
      z-index: 2;
      top: 120px;
      left: 16px;

      display: none;
    }

    .right {
      flex-direction: column;
      gap: 24px;

      .name-desktop {
        @include shadowed-text.medium();

        z-index: 2;
        max-width: 550px;
        font-size: 30px;
        color: colors.$grey-100;
      }

      .content {
        z-index: 2;
        transform: translateZ(0);

        flex-direction: column;
        gap: 18px;

        min-height: 235px;
        padding: 14px 20px;

        background-color: white;
        border-top-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;

        .top-line,
        .bottom-line {
          align-items: center;
          justify-content: space-between;

          .more {
            @include button.outlined($color-hover: colors.$green);
          }
        }

        .top-line {
          .date {
            font-weight: 600;
            color: colors.$green;
          }

          .type {
            font-weight: 600;
            color: colors.$gold;
            text-transform: capitalize;
          }
        }

        .text {
          @include text-ellipsis.multi-line(4);

          overflow: hidden;
          line-height: 20px;
        }

        .access-mobile,
        .cta-mobile {
          display: none;
        }
      }
    }
  }
}

@media (width <= 1024px) {
  .event-slider {
    .event {
      width: 100%;
    }
  }
}

@include breakpoints.mobile() {
  .link {
    @include misc.bold-link();

    display: block;

    // margin-top: 0; TODO: Delet it
    margin-bottom: 30px;
    text-align: center;
  }

  .event-slider {
    width: 100%;

    :deep(.title-header) {
      .right {
        display: none;
      }
    }

    .event {
      flex-direction: column;
      gap: 16px;

      width: 100%;
      height: 100%;
      padding: 0;

      border: colors.$grey-300 solid 1px;
      border-radius: border-radius.$small;

      .image {
        position: inherit;

        height: 200px;

        border-radius: 0%;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      .icon-container {
        display: none;
      }

      .name-mobile {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;

        padding: 0 16px;

        color: #f8f8f8;
        text-overflow: ellipsis;

        -webkit-line-clamp: 2;
        line-clamp: 2;
      }

      .right {
        gap: 0;

        .name-desktop {
          display: none;
        }

        .content {
          min-height: unset;
          padding: 0 16px 16px 24px;

          .top-line {
            flex-direction: column;
            align-items: start;
          }

          .bottom-line {
            display: none;
          }

          .access-mobile,
          .cta-mobile {
            display: flex;
          }

          .access-mobile {
            font-size: 13px;
            color: colors.$grey-700;
          }

          .cta-mobile {
            @include button.outlined($color-hover: colors.$green);

            align-self: center;
          }
        }
      }
    }
  }
}
</style>
